<template>
  <section class="terms-service">
    <h2 class="terms-service__title text-title">Terms and Conditions</h2>
    <p class="terms-service__text text-base">
      Welcome to browngrotta arts! Our services and products are provided subject to the following
      terms and conditions. By using the website, you agree to be bound by these terms and
      conditions. Please read them carefully, as they contain important provisions relating to your
      use of the site, including an agreement to engage in binding arbitration to resolve any
      disputes between us. The website is owned and operated by browngrotta arts ("browngrotta
      arts").
    </p>
    <p class="terms-service__sub-title text-base">
      You should review our Privacy Policy to understand our privacy practices.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      License and Website Access
    </p>
    <p class="terms-service__text text-base">
      We grant you a limited, revocable license to access and make personal use of the website as
      our customer. However, you are not permitted to:
    </p>
    <ul class="terms-service__list">
      <li class="terms-service__text text-base">
        reproduce, duplicate, copy, sell or otherwise exploit the website or any image, video,
        listing, description, price, page layout, page design, trade dress, trademark, logo or other
        content ("Website Content") for any commercial purpose, except as expressly provided;
      </li>
      <li class="terms-service__text text-base">
        use a robot, spider or data mining or extraction tool or process to monitor, extract or copy
        Website Content (except in the operation or use of internet "search engines," hit counters
        or similar technology);
      </li>
      <li class="terms-service__text text-base">
        use any meta tags, search terms, key terms, or the like that contain the website’s name or
        trademarks used on the website;
      </li>
      <li class="terms-service__text text-base">
        engage in any activity that interferes with the website or another user’s ability to use the
        website;
      </li>
      <li class="terms-service__text text-base">
        modify, create derivative works from, reverse engineer, decompile or disassemble any
        technology used to provide the website and the services offered on the website; or
      </li>
      <li class="terms-service__text text-base">
        assist or encourage any third party in engaging in any activity prohibited by these Terms of
        Use.
      </li>
    </ul>
    <p class="terms-service__text text-base">
      Any use of the website or Website Content that is not expressly authorized herein is
      prohibited and immediately terminates the license granted herein.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Electronic Communications
    </p>
    <p class="terms-service__text text-base">
      Email: You consent to receive communications from us by e-mail. You agree that all agreements,
      notices, disclosures and other communications that we provide to you by e-mail satisfy any
      legal requirement that such communications be in writing.
    </p>
    <p class="terms-service__text text-base">
      Text Messages: If you opt in to receive SMS (text messages) from browngrotta arts (as/when
      available), or use a mobile application, the following additional terms and conditions
      ("Mobile Terms") also apply to you. Your access to the Sites via your mobile device or use of
      a mobile application confirms your agreement to these Mobile Terms, as well as the rest of the
      Terms and Conditions.
    </p>
    <p class="terms-service__text text-base">
      By opting in to receive SMS text messages, you agree to receive promotional SMS text messages
      on your mobile device. You do not need to receive SMS text messages to use or purchase any
      goods or services from the browngrotta arts website. By agreeing to receive SMS messages from
      browngrotta arts, you certify that you are over 18 years of age and (a) you are the mobile
      account holder or (b) you have the account holder’s permission to enroll the designated mobile
      phone number and can incur any mobile message or data charges. You agree that you are solely
      responsible for all message and data charges that apply to use of your mobile device to access
      the Sites or use of a mobile application. All such charges are billed by and payable to your
      mobile service provider. Please contact your participating mobile service provider for pricing
      plans, participation status and details. You understand that wireless through Wi-Fi or a
      participating mobile service provider may not be available in all areas at all times and may
      be affected by product, software, coverage or other service changes made by your mobile
      service provider or otherwise. Additional terms and conditions may apply to your use of a
      mobile application, based on the type of mobile device on which you install and use the mobile
      application. You can opt out of receiving SMS messages at any time.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Your Account</p>
    <p class="terms-service__text text-base">
      In the course of using the browngrotta arts website or services, you may be asked to set up a
      user account and to provide certain personal information. The personal information collected
      shall be governed by our Privacy Policy located at [insert web address]. If you use the
      website, you are responsible for maintaining the confidentiality of your account and password
      and for restricting access to your computer, and you agree to accept responsibility for all
      activities that occur under your account or password. We reserve the right to refuse service,
      terminate accounts, remove or edit content, or cancel orders at our sole discretion. We will
      not knowingly collect personally identifiable information from any person that is actually
      known to us to be a child under the age of 13 years old. If you are under 13 years of age,
      please do not use or access our website or services.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Use of Material and Shareable Content From This Site
    </p>
    <p class="terms-service__text text-base">
      This website and the material on this website, including but not limited to software, files,
      graphics, artwork, videos and data (“Shareable Content”), is the property of and owned by
      browngrotta arts or its licensors or affiliates and is protected by copyright, trademark and
      other intellectual property laws of the United States and other countries. You may display,
      electronically copy and print hard copy versions of the Shareable Content contained on this
      website only for your personal and non-commercial use. You may not modify or delete the
      Shareable Content that you access from our website in any manner. You may not modify or delete
      any copyright, trademark or other proprietary notice that appears on the Shareable Content
      that you access from our website. When you access Shareable Content from our website, you are
      only granted a limited use and that use may be revoked at browngrotta art’s sole discretion at
      any time and for any reason. browngrotta arts and its licensors or affiliates retain full and
      complete title and ownership in the Shareable Content on this website and to any and all
      intellectual property rights contained therein. Any use of the Shareable Content not expressly
      described herein, including without limitation the modification, distribution, transmission,
      performance, broadcast, publication, licensing, reverse engineering, transfer or sale of, or
      the creation of any derivative work from the material, information, software, products or
      services offered on our website, is strictly prohibited.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Featured Trademarks
    </p>
    <p class="terms-service__text text-base">
      Our website features trademarks, service marks and logos that are the property of us or our
      affiliates or licensors. Our website may also include the trademarks, service marks or logos
      of third parties. Such marks are the property of their respective owners. You agree to not use
      such marks in any manner without the prior written approval of the appropriate intellectual
      property mark owner. Our website and its Website Content includes without limitation text,
      designs, graphics, art, images, videos, and code that is protected under the Copyright Laws of
      the United States and other countries.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Copyright & Trademark Compliance; Complaints
    </p>
    <p class="terms-service__text text-base">
      We honor the intellectual property rights of others. If you believe that your work has been
      copied or used on the website in a way that constitutes copyright or trademark infringement,
      please notify us by following the procedure set forth in the immediately following paragraph.
      We do not reproduce or manufacture the art or products offered on our site, but rather we
      display or license the art or products from third party suppliers that represent to us that
      they have sufficient rights to allow us to display and sell the art or products on the
      website. Upon receipt of any bona fide claim of infringement, or upon becoming aware of any
      actual or alleged infringement by any other means, we will remove such actual or alleged
      infringing art or product(s) from the website and/or cease sales of the art or product(s)
      pending our investigation.
    </p>

    <ul class="terms-service__list">
      <li class="terms-service__text text-base no-marks">
        Notice and Procedure for Making Claims of Copyright / Trademark Infringement
      </li>
      <br /><br />
      <li class="terms-service__text text-base no-marks">
        If you believe that your work has been copied, distributed or used on the website in a way
        that constitutes copyright or trademark infringement, please provide the following
        information:
      </li>
      <li class="terms-service__text text-base no-marks">
        (a) an electronic or physical signature of the person authorized to act on behalf of the
        owner of the copyright interest;
      </li>
      <li class="terms-service__text text-base no-marks">
        (b) a description of the copyrighted work that you claim has been infringed;
      </li>
      <li class="terms-service__text text-base no-marks">
        (c) a description of where the material that you claim is infringing is located on the Site;
      </li>
      <li class="terms-service__text text-base no-marks">
        (d) your address, telephone number, and e-mail address;
      </li>
      <li class="terms-service__text text-base no-marks">
        (e) a statement by you that you have a good faith belief that the disputed use is not
        authorized by the copyright owner, its agent, or the law; and
      </li>
      <li class="terms-service__text text-base no-marks">
        (f) a statement by you, made under penalty of perjury, that the above information in your
        notice is accurate and that you are the copyright owner or authorized to act on the
        copyright owner's behalf.
      </li>
      <br /><br />
      <li class="terms-service__text text-base no-marks">
        Submit this information to:<br />
        browngrotta arts<br />Attention: Tom Grotta<br />276 Ridgefield Road<br />Wilton, CT
        06897<br />art@browngrotta.com
      </li>
    </ul>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Reviews, Comments, Communications and Other Content
    </p>
    <p class="terms-service__text text-base">
      Visitors may post or submit reviews, comments, suggestions, communications and other
      information via our website, by email or through one of our website operation partners, or on
      third party social media channels. You agree not to submit, transmit, or otherwise make
      available in any manner any content (i) that is unlawful, harmful, obscene, hateful,
      threatening, abusive, defamatory, harassing, libelous, invasive of another's privacy, or is
      otherwise objectionable, (ii) that infringes the intellectual property rights of any party or
      (iii) which contains a chain letter or constitutes any form of commercial solicitation,
      political campaign, mass mailing, "spam" or software virus. You may not use a false e-mail
      address or otherwise provide information that would be misleading as to the origin of such
      content. Although we do not regularly review content you submit, we reserve the right to edit
      or remove any content that we find objectionable for any reason. You represent and warrant
      that you possess all necessary rights to use the content that you submit and that use of such
      content does not violate generally accepted standards of decency. You agree to defend,
      indemnify, and hold us harmless from and against any and all claims arising out of or relating
      to any content that you submit. By submitting content, you automatically grant to us, or
      warrant that the owner of such content has expressly granted to us, a royalty-free, perpetual,
      irrevocable, worldwide, unlimited, nonexclusive right and license to use, reproduce, create
      derivative works from, modify, publish, edit, translate, distribute, perform and display your
      user name or the content in any media, form, format, manner or forum now known or hereafter
      developed without compensation to you. We take no responsibility and assume no liability for
      any content posted by visitors to our website.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Use of Services</p>
    <p class="terms-service__text text-base">
      We offer our website users certain services that involve the use of images of our art or
      products. The art and product images provided through these services are protected by
      international copyright laws. Users of these services may not: (a) alter, modify or manipulate
      any art or product image; (b) use an art or product image in any form other than the form
      provided by us; (c) reproduce art or product images other than as intended through the use of
      this service; (d) remove any code or identifying information from an art image or product
      image; or (e) inactivate the link associated with any art or product image or cause an image
      to ultimately link to a destination other than our website.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Third Party Links and/or Services
    </p>
    <p class="terms-service__text text-base">
      The website or services may contain links to or services offered by third parties that are not
      under the control of browngrotta arts (“Third Party Services”). Any such Third Party Services
      are provided for your convenience only and you access them at your own risk. The inclusion of
      any link to a Third-Party Website on our website does not constitute an endorsement or
      sponsorship by us of such Third-Party Website, or the information, content, products,
      services, advertising, code or other materials presented on or through such Third-Party
      Website. Any reliance on the contents of a Third-Party Website is done at your own risk. When
      you access such Third-Party Websites, you acknowledge and agree that (a) these other websites
      and services are not under our control and we have no responsibility for the information,
      content, products, services, advertising, code or other materials provided by or through any
      Third-Party Website, (b) there are risks in accessing these Third-Party Websites, and you
      assume all responsibilities and consequences resulting from such risks, and under no
      circumstances will we be responsible for any consequences resulting from such risks or for the
      accuracy, legality, appropriateness or any other aspect of the content or function of such
      websites, and (c) additional or different Terms of Use may apply when you are accessing and
      using such Third-Party Websites. By using our website or services, you release and hold us
      harmless from any and all liability arising from your use of any such Third-Party Website or
      your reliance on any content, goods or services provided by the relevant third party. If there
      is a dispute between you and any third party, we are under no obligation to become involved.
      You should direct any concerns to the relevant third party.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Terms of Sale & Refunds
    </p>
    <p class="terms-service__text text-base">
      These terms of sale apply to all offers made by browngrotta arts, all purchases and orders
      placed by you (excluding commissioned work that has been custom ordered by you) and all
      agreements concluded between us in relation to the art or products and services offered on
      this website.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Overall Return Rights:
    </p>
    <p class="terms-service__text text-base">
      These terms of sale apply to all offers made by browngrotta arts, all purchases and orders Our
      General Return Policy. You may return items purchased at browngrotta.com or received as a gift
      within 14 business days of receiving them.
    </p>
    <p class="terms-service__text text-base">
      One important exception that applies to all consumers including EU and UK consumers: Since all
      commissioned artwork is custom ordered to reflect client requirements, commission artwork may
      not be returned.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Right to Cancel within the EU and the UK:
    </p>
    <p class="terms-service__text text-base">
      If you are ordering from within the EU or the UK, you have a 14-day period to cancel the sale
      for any reason and obtain a full refund. If the artwork has been shipped before you notify
      browngrotta arts of your cancellation then your refund will be processed within 14 days of
      browngrotta arts receiving your returned item. Again, the 14-day cancellation period does not
      apply to commissioned artwork that has been custom ordered by you.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      The Return Process
    </p>
    <p class="terms-service__text text-base">
      Contact us by either calling 1-203-834-0623 or e-mailing us at art@browngrotta.com to obtain
      return instructions.
    </p>
    <p class="terms-service__text text-base">
      Repack the product in its original packaging materials. If the package's condition is not
      suitable for a safe return back to us then provide your own return packaging. EU consumers
      will be refunded for the cost of their return packaging.
    </p>
    <p class="terms-service__sub-title text-base">
      All consumers:
      <span class="terms-service__text text-base"
        >You will be responsible for artwork damaged in return transit.</span
      >
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Shipping Costs</p>
    <p class="terms-service__text text-base">
      General consumers: You will be responsible for shipping costs both ways if you return an item
      that is not defective or has not been received by mistake. If you return a product because it
      was defective or damaged or because we shipped you something that you did not order,
      browngrotta arts will pay the return-shipping costs.
    </p>
    <p class="terms-service__sub-title text-base">
      EU and UK consumers:
      <span class="terms-service__text text-base">You will be refunded all shipping costs.</span>
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Damaged in Transit
    </p>
    <p class="terms-service__text text-base">
      If your package appears damaged, or, if once it is opened, you find damaged artwork, call us
      at 203-834-0623, so we can arrange to have it picked up and inspected. Please retain all
      original packing materials. You may be responsible for artwork damaged in return transit.<br />
      Note: Depending on the circumstances, we may not provide a refund if you return items to us
      that have been damaged, altered or worn.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Refunds</p>
    <p class="terms-service__text text-base">
      You can expect a refund, if applicable, in the same form of payment originally used for
      purchase within 14 business days after browngrotta arts receives your return, providing it
      arrives safely.
    </p>
    <p class="terms-service__text text-base">
      We will notify you of the status of your return by e-mail or postal mail. If you have any
      questions, please call us 203-834-0623, Monday-Friday 9am-6pm, EST or contact us by email at
      art@browngrotta.com.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Product Descriptions
    </p>
    <p class="terms-service__text text-base">
      We attempt to describe our art and products offered as accurately as possible. However, we do
      not warrant that art or product descriptions or other content of the website are 100%
      accurate, complete, reliable, current or error-free. If a piece of art or product offered by
      us and purchased by you is not as described (excluding commissioned work that has been custom
      ordered) then your sole remedy is to return it in unused condition for a conforming
      replacement, refund or credit.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Disclaimer</p>
    <p class="terms-service__text text-base">
      We are not liable for any financial damage (including but not limited to the cost of any
      replacement purchase) that may occur because of non-timely arrival or any lack of availability
      or conformity of the product.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Limitation of Liability
    </p>
    <p class="terms-service__text text-base">
      We do not accept responsibility that the website, its servers, or e-mail sent from the website
      are free of viruses or other harmful components. We will not be liable for any damages of any
      kind arising from the use of the website, including, but not limited to direct, indirect,
      incidental, punitive or consequential damages. Certain laws do not allow limitations as
      contained in this clause. If these laws apply, some or all of the above limitations may not
      apply to you and you might have additional rights.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      DISCLAIMER OF WARRANTIES
    </p>
    <p class="terms-service__text text-base">
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF OUR WEBSITE AND SERVICES ARE
      AT YOUR SOLE RISK, AND THE WEBSITE AND ALL SERVICES, CONTENT, FUNCTIONS, INFORMATION,
      MATERIALS AND PRODUCTS AVAILABLE THROUGH THE WEBSITE ARE PROVIDED ON AN “ AS IS ” AND “ AS
      AVAILABLE ” BASIS WITHOUT WARRANTY OF ANY KIND, INCLUDING BUT NOT LIMITED TO THE IMPLIED
      WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION, MERCHANTABILITY AND FITNESS FOR A
      PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
      ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
    </p>
    <p class="terms-service__text text-base">
      WITHOUT LIMITING THE FOREGOING, WE (I) MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING ANY
      PRODUCTS OR SERVICES ORDERED OR PROVIDED VIA OUR WEBSITE, AND (II) HEREBY DISCLAIM, AND YOU
      HEREBY WAIVE, ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH RESPECT THERETO, INCLUDING
      WITHOUT LIMITATION ANY AND ALL WARRANTIES AND REPRESENTATIONS MADE IN PRODUCT OR SERVICES
      LITERATURE OR OTHERWISE ON THE WEBSITE. YOU ARE SOLELY RESPONSIBLE FOR, AND ASSUME ALL RISKS
      RELATED TO, THE USE OF ANY PRODUCTS OR SERVICES MADE AVAILABLE TO YOU VIA OUR WEBSITE. WE
      FURTHER DO NOT WARRANT OR GUARANTEE THAT: (A) THE WEBSITE OR ANY SERVICES, CONTENT, FUNCTIONS,
      INFORMATION, MATERIALS OR PRODUCTS AVAILABLE THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS
      OR THAT THE QUALITY OF ANY GOODS, PRODUCTS OR SERVICES AVAILABLE ON THE WEBSITE WILL MEET YOUR
      EXPECTATIONS; (B) ANY INFORMATION PROVIDED WILL BE TIMELY, ACCURATE, RELIABLE, PRECISE,
      THOROUGH, CORRECT OR COMPLETE; (C) THE WEBSITE OR ANY SERVICES, CONTENT, FUNCTIONS,
      INFORMATION, MATERIALS AND PRODUCTS AVAILABLE THROUGH THE WEBSITE WILL BE AVAILABLE AT ANY
      PARTICULAR TIME OR PLACE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (D) ANY DEFECTS OR
      ERRORS IN THE WEBSITE WILL BE CORRECTED; (E) THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER
      HARMFUL COMPONENTS; OR (F) ANY PARTICULAR RESULT OR OUTCOME CAN BE ACHIEVED OR THAT THE
      RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE WILL BE ACCURATE OR RELIABLE.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      LIMITATION OF LIABILITY
    </p>
    <p class="terms-service__text text-base">
      IN NO EVENT SHALL WE BE LIABLE, WHETHER IN AN ACTION BASED ON A CONTRACT INDEMNIFICATION,
      OBLIGATION, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE), COLLATERALLY OR ARISING FROM ANY
      STATUTORY DUTY, PRE-CONTRACT OR OTHER REPRESENTATIONS, OR OTHERWISE, HOWEVER ARISING, FOR ANY
      ECONOMIC LOSSES (INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUES, PROFITS, CONTRACTS,
      BUSINESS, GOODWILL OR ANTICIPATED SAVINGS) OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED
      TO (A) ANY BREACH OF THIS AGREEMENT, (B) THE USE OF, OR THE INABILITY TO USE, THE WEBSITE OR
      THE CONTENT, MATERIALS, INFORMATION, FUNCTIONS OR PRODUCTS AVAILABLE THROUGH THE WEBSITE, (C)
      YOUR PROVISION OF INFORMATION VIA THE WEBSITE; (D) ANY INACCURACY OR OMISSION CONCERNING ANY
      OF THE INFORMATION PROVIDED ON THE WEBSITE; (E) ANY CONTENT POSTED, TRANSMITTED, EXCHANGED OR
      RECEIVED BY OR ON BEHALF OF ANY USER OR OTHER PERSON ON OR THROUGH THE WEBSITE, OR (F) LOST
      BUSINESS OR LOST SALES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p class="terms-service__text text-base">
      WITHOUT LIMITING THE FOREGOING, ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, AUDIO OR SOFTWARE
      DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE WEBSITE ARE ACCESSED, DOWNLOADED OR OBTAINED AT
      YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
      COMPUTER SYSTEM, MOBILE DEVICE OR OTHER PROPERTY THAT RESULTS THEREFROM OR FROM YOUR USE OF
      ANY SUCH MATERIALS.
    </p>
    <p class="terms-service__text text-base">
      SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR DIRECT,
      INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN
      USERS.
    </p>
    <p class="terms-service__text text-base">
      IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF OUR DAMAGES, LOSSES, AND CAUSES OF ACTION
      (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING
      FROM THESE TERMS OF USE OR YOUR USE OF THE WEBSITE OR THE CONTENT, MATERIALS, INFORMATION,
      FUNCTIONS OR PRODUCTS AVAILABLE THROUGH THE WEBSITE EXCEED, IN THE AGGREGATE, FIFTY DOLLARS
      ($50.00).
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Disclaimer</p>
    <p class="terms-service__text text-base">
      We are not liable for any financial damage (including but not limited to the cost of any
      replacement purchase) that may occur because of non-timely arrival or any lack of availability
      or conformity of the product.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Applicable Law and Venue
    </p>
    <p class="terms-service__text text-base">
      These Terms of Use incorporate our Privacy Policy and any other terms applying to your use of
      our website. These Terms of Use and the relationship between you and us shall be governed by
      appropriate federal laws and regulations and the laws of the State of Connecticut without
      regard to its conflict of law provisions. You agree that any cause of action that may arise
      under these Terms of Use shall be commenced and heard in the appropriate court in the State of
      Connecticut. You agree to submit to the personal and exclusive jurisdiction of the courts
      located within the State of Connecticut.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">Severability</p>
    <p class="terms-service__text text-base">
      If any of the terms or conditions herein shall be deemed invalid, void, or for any reason
      unenforceable, that term or condition shall be deemed severable and shall not affect the
      validity and enforceability of any remaining term or condition.
    </p>

    <p class="terms-service__sub-title terms-service__new-paragraph text-link">
      Changes to these Terms and Conditions
    </p>
    <p class="terms-service__text text-base">
      We reserve the right in our sole discretion to change or modify these Terms and Conditions at
      any time by posting revised Terms and Conditions on our website. Such changes will be
      effective immediately upon their posting to the website. It is your responsibility to check
      the website for changes. Your continued use of our website following any posted changes
      implies your acceptance of those changes.
    </p>

    <p class="terms-service__sub-title text-base">
      Last Posted:
      <span class="terms-service__text text-base">May 2022</span>
    </p>
    <IconButton
      class="terms-service__close button--md button--outline-white"
      @click="$router.go(-1)"
    >
      <IconClose width="18" height="18"></IconClose>
    </IconButton>
  </section>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    IconButton,
    IconClose,
  },
  head: {
    title: function () {
      return {
        inner: "Terms and Conditions browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Terms and Conditions browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Terms and Conditions browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Terms and Conditions browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Terms and Conditions browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Terms and Conditions browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Terms and Conditions browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Terms and Conditions browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Terms and Conditions browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-service {
  position: relative;
  padding: 17rem 31.5rem 18rem;
  @media screen and (max-width: $md) {
    padding: 17rem 10.5rem 18rem;
  }
  @media screen and (max-width: $xs) {
    padding: 17rem 25px 18rem;
  }
  &__title {
    font-size: 4rem;
    line-height: 1.25;
    letter-spacing: -1.33px;
    margin-bottom: 3rem;
  }
  &__sub-title {
    margin-bottom: 0.5rem;
  }
  &__text {
    font-weight: 100;
    color: rgba($color: $white, $alpha: 0.8);
    margin-bottom: 1.5rem;
    & > span {
      font-weight: 400;
    }
  }
  &__new-paragraph {
    margin-top: 3.5rem;
  }
  &__list {
    margin-left: 105px;
    @media screen and (max-width: $xs) {
      margin-left: 0px;
    }

    .no-marks {
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }

    & > li {
      &::before {
        width: 0.55rem;
        height: 0.55rem;
        margin-right: 5px;
        background-color: white;
        position: relative;
        float: left;
        top: 1rem;
      }
    }
  }
  &__close {
    position: absolute;
    top: 4.5rem;
    right: 10.5rem;
    z-index: 1000;
    @media screen and (max-width: $md) {
      right: 5rem;
    }
    @media screen and (max-width: $xs) {
      right: 25px;
    }
  }
}
</style>
